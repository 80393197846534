var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.plantData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t("xFetchError", { value: _vm.$t("plant") }))+" ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t("xNotFound", { value: _vm.$t("plant") }))+" "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'plants' }}})],1)]),(_vm.plantData)?[_c('b-row',{staticClass:"align-self-stretch mb-2"},[_c('b-col',{staticClass:"mb-2 mb-md-0",attrs:{"cols":"12","md":"8"}},[_c('plant-view-plant-info-card',{staticClass:"h-100",attrs:{"plant-data":_vm.plantData}})],1),_c('b-col',{staticStyle:{"max-height":"350px"},attrs:{"cols":"12","md":"4"}},[(_vm.plantUser.length)?_c('plant-view-plant-users-card',{staticClass:"h-100",attrs:{"plant-users":_vm.plantUser},on:{"update:plantUsers":function($event){_vm.plantUser=$event},"update:plant-users":function($event){_vm.plantUser=$event},"userupdate":_vm.updateUser}}):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-link',{attrs:{"to":{
            name: 'animals',
            params: { id: _vm.$route.params.id },
          }}},[_c('plant-link-card',{staticClass:"min-height-50",attrs:{"title":_vm.$t('animals')}},[_c('BeefSvg',{staticClass:"text-truncate",staticStyle:{"width":"30px","height":"40px"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-link',{attrs:{"to":{
            name: 'animals-group',
            params: { id: _vm.$route.params.id },
          }}},[_c('plant-link-card',{staticClass:"min-height-50",attrs:{"title":_vm.$t('animalsGroup')}},[_c('BeefSvg',{staticClass:"text-truncate",attrs:{"width":"50"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-link',{attrs:{"to":{
            name: 'plantRations',
            params: { id: _vm.$route.params.id, tur: 'dairy' },
          }}},[_c('plant-link-card',{staticClass:"min-height-50",attrs:{"title":_vm.$t('dairyRations')}},[_c('DairySvg',{staticClass:"text-truncate",staticStyle:{"width":"30px","height":"40px"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-link',{attrs:{"to":{
            name: 'plantRations',
            params: { id: _vm.$route.params.id, tur: 'beef' },
          }}},[_c('plant-link-card',{staticClass:"min-height-50",attrs:{"title":_vm.$t('beefRations')}},[_c('BeefSvg',{staticClass:"text-truncate",attrs:{"width":"50"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('b-link',{attrs:{"to":{ name: 'ingredients', params: { id: _vm.$route.params.id } }}},[_c('plant-link-card',{staticClass:"min-height-50",attrs:{"title":_vm.$t('ingredients')}},[_c('IngredientSvg',{staticClass:"text-truncate",attrs:{"width":"30","height":"30"}})],1)],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('b-link',{attrs:{"to":{
            name: 'ingredientsPrices',
            params: { id: _vm.$route.params.id },
          }}},[_c('plant-link-card',{staticClass:"min-height-50",attrs:{"title":_vm.$t('ingredientPrices')}},[_c('PricesSvg',{staticClass:"text-truncate",attrs:{"width":"30","height":"30"}})],1)],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"4","cols":"12"}},[_c('b-link',{attrs:{"to":{ name: 'nutrients', params: { id: _vm.$route.params.id } }}},[_c('plant-link-card',{staticClass:"min-height-50",attrs:{"title":_vm.$t('nutrients')}},[_c('NutrientSvg',{staticClass:"text-truncate",attrs:{"width":"30","height":"30"}})],1)],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('plant-view-plant-orders-card',{staticClass:"h-100",attrs:{"plant-data":_vm.plantData}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('plant-view-plant-visit-card',{attrs:{"plant-visits":_vm.plantData},on:{"update:plantVisits":function($event){_vm.plantData=$event},"update:plant-visits":function($event){_vm.plantData=$event}}})],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }