<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center pb-1">
      <div class="truncate">
        <h2 class="font-weight-bolder">
          {{ title }}
        </h2>
        <span>{{ title }}</span>
      </div>
      <b-avatar :variant="`light-${color}`" size="50" class="paddingSlot">
        <slot />
        <feather-icon v-if="icon" size="21" :icon="icon" />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: null,
      default: null,
    },
  },
  computed: {},
  methods: {
    gradientToColor(color) {
      const gradientToColors = {
        primary: "#A9A2F6",
        success: "#55DD92",
        warning: "#ffc085",
        danger: "#F97794",
        info: "#59E0F0",
        secondary: "#B4B9BF",
        light: "#D0D4DB",
        dark: "#919191",
      };

      return gradientToColors[color];
    },
  },
};
</script>
<style scoped>
.paddingSlot {
  padding: 5px;
}
</style>
