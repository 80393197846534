<template>
  <b-card>
    <b-row>
      <!-- Plant Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Plant Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="
              'https://api.herdoncloud.com/Plant_Pictures/' +
              plantData.pictureurl
            "
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ plantData.plantname }}
              </h4>
              <span class="card-text">{{ plantData.responsiblemobile }}</span>
              <p class="card-text">
                {{ plantData.email }}
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex flex-wrap justify-content-end">
          <b-button
            v-canSee="'read'"
            :to="{ name: 'plants' }"
            variant="outline-danger"
            class="ml-1"
            @click="deletePlant(plantData.id)"
          >
            {{ $t("delete") }}
          </b-button>
        </div>
      </b-col>
      <!-- Right Col: Table -->
      <b-col cols="12" xl="12" class="mt-2">
        <table class="mt-4 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="TagIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("plantName") }}</span>
            </th>
            <td class="pb-50">
              {{ plantData.plantname }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("mobile") }}</span>
            </th>
            <td class="pb-50">
              {{ plantData.responsiblemobile }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("resonsible") }}</span>
            </th>
            <td class="pb-50">
              {{ plantData.responsible }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("address") }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ plantData.address }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("city") }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ plantData.city }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import usePlantList from "../usePlantList";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    plantData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { deletePlant } = usePlantList();
    var plantRole = (roleId) => {
      return roleId == "1"
        ? "Yönetici"
        : roleId == "2"
        ? "Müdür"
        : "Normal Kullanıcı";
    };
    return {
      avatarText,
      deletePlant,
      plantRole,
    };
  },
};
</script>
