<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="plantData === undefined">
      <h4 class="alert-heading">
        {{ $t("xFetchError", { value: $t("plant") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("plant") }) }}
        <b-link class="alert-link" :to="{ name: 'plants' }" />
      </div>
    </b-alert>
    <template v-if="plantData">
      <b-row class="align-self-stretch mb-2">
        <b-col cols="12" md="8" class="mb-2 mb-md-0">
          <plant-view-plant-info-card class="h-100" :plant-data="plantData" />
        </b-col>
        <b-col cols="12" md="4" style="max-height: 350px">
          <plant-view-plant-users-card
            v-if="plantUser.length"
            class="h-100"
            :plant-users.sync="plantUser"
            @userupdate="updateUser"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-link
            :to="{
              name: 'animals',
              params: { id: $route.params.id },
            }"
          >
            <plant-link-card :title="$t('animals')" class="min-height-50">
              <BeefSvg
                class="text-truncate"
                style="width: 30px; height: 40px"
              />
            </plant-link-card>
          </b-link>
        </b-col>

        <b-col cols="12" md="6">
          <b-link
            :to="{
              name: 'animals-group',
              params: { id: $route.params.id },
            }"
          >
            <plant-link-card :title="$t('animalsGroup')" class="min-height-50">
              <BeefSvg class="text-truncate" width="50" />
            </plant-link-card>
          </b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-link
            :to="{
              name: 'plantRations',
              params: { id: $route.params.id, tur: 'dairy' },
            }"
          >
            <plant-link-card :title="$t('dairyRations')" class="min-height-50">
              <DairySvg
                class="text-truncate"
                style="width: 30px; height: 40px"
              />
            </plant-link-card>
          </b-link>
        </b-col>

        <b-col cols="12" md="6">
          <b-link
            :to="{
              name: 'plantRations',
              params: { id: $route.params.id, tur: 'beef' },
            }"
          >
            <plant-link-card :title="$t('beefRations')" class="min-height-50">
              <BeefSvg class="text-truncate" width="50" />
            </plant-link-card>
          </b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="4" md="6" cols="12">
          <b-link
            :to="{ name: 'ingredients', params: { id: $route.params.id } }"
          >
            <plant-link-card class="min-height-50" :title="$t('ingredients')">
              <IngredientSvg class="text-truncate" width="30" height="30" />
            </plant-link-card>
          </b-link>
        </b-col>
        <b-col lg="4" md="6" cols="12">
          <b-link
            :to="{
              name: 'ingredientsPrices',
              params: { id: $route.params.id },
            }"
          >
            <plant-link-card
              class="min-height-50"
              :title="$t('ingredientPrices')"
            >
              <PricesSvg width="30" height="30" class="text-truncate" />
            </plant-link-card>
          </b-link>
        </b-col>
        <b-col sm="12" lg="4" cols="12">
          <b-link :to="{ name: 'nutrients', params: { id: $route.params.id } }">
            <plant-link-card class="min-height-50" :title="$t('nutrients')">
              <NutrientSvg width="30" height="30" class="text-truncate" />
            </plant-link-card>
          </b-link>
        </b-col>
      </b-row>
      <!-- First Row -->

      <b-row class="mb-2">
        <b-col cols="12" md="6">
          <plant-view-plant-orders-card class="h-100" :plant-data="plantData" />
        </b-col>
        <b-col cols="12" md="6">
          <plant-view-plant-visit-card :plant-visits.sync="plantData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import PlantViewPlantInfoCard from "./PlantViewPlantInfoCard.vue";
import PlantViewPlantVisitCard from "./PlantViewPlantVisitCard.vue";
import PlantViewPlantUsersCard from "./PlantViewPlantUsersCard.vue";
import PlantViewPlantOrdersCard from "./PlantViewPlantOrderCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import BeefSvg from "@/assets/images/icons/beef.svg";
import DairySvg from "@/assets/images/icons/milk.svg";
import IngredientSvg from "@/assets/images/icons/ingredients.svg";
import PricesSvg from "@/assets/images/icons/prices.svg";
import NutrientSvg from "@/assets/images/icons/products.svg";
import PlantLinkCard from "./PlantLinkCard.vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    //svg

    BeefSvg,
    DairySvg,
    IngredientSvg,
    PricesSvg,
    NutrientSvg,
    // Local Components

    PlantViewPlantInfoCard,
    PlantViewPlantVisitCard,
    PlantViewPlantUsersCard,
    PlantViewPlantOrdersCard,
    PlantLinkCard,
  },
  data: function () {
    return {
      plantData: {},
      plantUser: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "PlantDetail") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/plants",
        text: VueI18n.t(`plants`),
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t(`plantDetails`),
        to: `/plant/${to.params.id}`,
        active: true,
      });
    }
    next();
  },
  async beforeCreate() {
    await store.dispatch("plantsModule/fetchPlant", {
      id: this.$store.state.app.selectedPlantId,
    });
    this.plantData = await store.state.plantsModule.selectedPlant;
    this.$route.meta.breadcrumb.splice(1, 1, {
      text: this.plantData.plantname,
      active: true,
    });
    this.plantUser = await store.dispatch(
      "plantsModule/getPlantUser",
      this.$store.state.app.selectedPlantId
    );
  },
  methods: {
    async updateUser(ids) {
      var isOk = await store.dispatch("plantsModule/updatePlantUser", {
        plantid: this.$store.state.app.selectedPlantId,
        ids: ids,
      });
      if (isOk)
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("plantUsers") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notupdated", { type: this.$t("plantUsers") }),
          },
        });
    },
  },
};
</script>
<style scoped>
.min-height-50 {
  min-height: 120px;
}
</style>
